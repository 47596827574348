import { useState, useRef } from "react";
import { motion } from 'framer-motion';
import { loaderVariants, slideIn } from "../utils/motion";
import { FaTelegram } from "react-icons/fa";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";

import { AiFillTwitterCircle, AiOutlineCopy, AiOutlineArrowRight, AiOutlineTwitter, AiOutlineRight, AiOutlineWallet, AiOutlineArrowLeft } from "react-icons/ai"
import Button from "../utils/Button";
import AnimeLogo from "../utils/AnimeLogo";
import CA from "../utils/Ca";
import GlowingHeader from "../utils/GlowingHeader";
import '../utils/glowing.css';
const Hero = ({ pryColor
    , secColor }
) => {


    const [isCopied, setIsCopied] = useState(false);
    const handleCopied = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 5000)
    }

    const contractRef = useRef();

    const copyContractText = () => {
        const text = contractRef.current.textContent;
        navigator.clipboard.writeText(text);
        console.log('Text copied: ' + text);
        handleCopied();
    };


    return (<div className="text-[#f2f2f2] relative  flex mx-auto flex-col gap-5 px-4 pt-0  items-center justify-center w-[90%] md:w-[80%] ">

        <div className="py-5"><img src="../img/logo.png" alt="logo" className=" w-full md:w-[75%] mx-auto" />
        </div>
        <div>
            <h1 className="text-[35px] uppercase text-[#f2f2f2] text-center md:text-[45px]">Goat Coin on solana</h1>
        </div>
        <div className="text-center mx-auto w-full">
            <h1>Total Supply: <span className="font-bold">1,000,000,000</span></h1>
        </div>
        <div className="flex flex-col items-center gap-2 ">
            <h1 className="font-bold">Contract: </h1>
            <p className="underline text-[#000] text-[12px]">0000000000000000000</p>
        </div>
        <div>
            <button className="flex button text-[25px] bg-[#0000008d] text-[#4CC9B9] gap-2 flex-row items-center p-2">
                <MdOutlineKeyboardArrowLeft />
                Buy Now                <MdOutlineKeyboardArrowRight />
            </button>
        </div>

        <div className="flex flex-row items-center justify-center  w-full gap-5 ">
            <a href="https://x.com/goatcoin_sol"><img src="../img/twitter2.webp" alt="twitter" className="w-[35px]" /></a>
            <a href="https://t.me/goatcoin_sol"><img src="../img/tg.webp" alt="twitter" className="w-[35px]" /></a>
            <a href="https://www.dexscreener.com/#"><img src="../img/dexs.png" alt="dextools" className="w-[35px] bg-white rounded-full" /></a>
            <a href="https://raydium.com/#"><img src="../img/raydium.webp" alt="twitter" className="w-[35px]" /></a>
         

        </div>
        <div>
            <h1 className="uppercase my-3 text-[25px] text-center">I love goats, do you?</h1>
            <div className="grid gap-3 grid-cols-1 md:grid-cols-2">
                <div className="p-2  ">
                    <img src="../img/pic-1.gif" alt="pic-1" className="object-cover h-full w-full" />
                </div>
                <div className="p-2  ">
                    <img src="../img/pic-2.gif" alt="pic-2"  className="h-full w-full"/>
                </div>
                <div className="p-2  ">
                    <img src="../img/pic-3.jpg" alt="pic-3"  className="h-full w-full"/>
                </div>
                <div className="p-2  ">
                    <img src="../img/pic-4.gif" alt="pic-4" className="h-full w-full" />
                </div>
          
            </div>

        </div>
    

        <div className="">
            <button className="flex button text-[25px] bg-[#0000008d] text-[#4CC9B9] gap-2 flex-row items-center p-2">
                <MdOutlineKeyboardArrowLeft />
                Buy Now                <MdOutlineKeyboardArrowRight />
            </button>
        </div>

        <div className="leading-[30px]">
            <h1 className="text-[18px] text-[#000000be] my-3 underline">Tokenomics</h1>
         <div className="flex flex-col md:flex-row gap-3 md:gap-5 items-center ">
            <ul className="flex flex-col gap-3">
                <li>Stealth Launch</li>
            
                <li>Liquidity Pool tokens burnt 🔥</li>
                <li>No Max Wallet</li>
            
            </ul>
            <ul className="flex flex-col gap-3">
                <li>80% Uniswap</li>
                <li>0% Buy and 0% Sell Tax </li>
                <li>Minting disabled at launch</li>
   
           
            </ul>
         </div>
        
        </div>
        <div className="mx-auto">
            <button className="flex button bg-[#0000008d] text-[#4CC9B9] gap-2 flex-row items-center text-[25px] p-2">
                <MdOutlineKeyboardArrowLeft />
                Buy Now                <MdOutlineKeyboardArrowRight />
            </button>
        </div>
      
        <div className="text-[16px] text-center">
            <p>Goat Coin, a meme coin, holds no inherent value or promise of financial rewards. It lacks a structured team or roadmap.</p> <br />
            <p>Ultimately, the coin serves no practical function and is solely inteded for entainment purposes.</p>
        </div>
        <div className="my-5 text-[#0000008d] font-bold">
            <h1>Goat Coin | 2024</h1>
        </div>
    </div>);
}

export default Hero;